import { faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { Component, Fragment } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TipConfiguration } from "../../Models/TipConfiguration";
import { CommonResponse } from "../../Models/CommonResponse";
import { storage } from "../../Utility/CartStorage";
import { Storage as _storage } from "../../Utility/Storage";
import { formatPrice } from "../../Utility/Common";
import { get, post } from "../../Utility/HttpService";
import {
  getTipConfiguration,
  getPickupTime,
  getDiscountsForFoodItems,
} from "../../Utility/URLService";
import PassInfoDialog from "../PassInfo/PassInfo";
import QuantityInfoDialog from "../QuantityInfo/QuantityInfo";
import { KIOSKSetting } from "../../Utility/Config";
import { PassInfoKey, ItemTreeRoot, CartItemKey } from "../../Utility/Config";

class Cart extends Component {
  getItemFromStorage = () => {
    return storage.getAllFoodItems();
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      restaurantNodeId: this.getRestaurantInfo("nodeId"),
      menuNodeId: this.getRestaurantInfo("menuNodeId"),
      animate: false,
      isCustomAmountDisplay: true,
      customTipInPersentage: false,
      customTipInDollar: false,
      navigateFrom: "cart",
      heading: "cart",
      count: 0,
      hideCart: true,
      closeIcon: true,
      show: true,
      cartItems: this.getItemFromStorage(),
      tipConfiguration: undefined,
      tipAmount: 0,
      customTip: "",
      isPassApplied: false,
      passInfo: {
        dialogOpen: false,
      },
      specials: {
        text: "",
      },
      quantityModal: {
        visible: false,
        onCloseEventCallBack: this.quantityModalCloseCallBack,
        quantity: 0,
      },
      pickupTime: "",
      isShowRestaurantWaitingTime: false,
      restaurantWaitingTimeMessage: window.ENV.IsShowRestaurantWaitingTime,
      checkoutButtonDisabled: false,
      isOtherRestaurantFoodItemsAddedToCart:
        this.isOtherRestaurantFoodItemsAddedToCart(),
      kioskSetting: JSON.parse(_storage.getItem(KIOSKSetting)),
      isCameraAvailable: false,
    };
    this.handleOpenCameraPopup();
    this.handleClick = this.handleClick.bind(this);
    //this.getTipConfigurations();
  }

  // Handle different restaurant items check
  isOtherRestaurantFoodItemsAddedToCart() {
    let cartInfo = storage.info();
    let restaurantInfo = storage.getRestaurantInfo();
    if (
      cartInfo.restaurantNodeId === 0 ||
      cartInfo.restaurantNodeId === restaurantInfo.nodeId
    ) {
      return false;
    } else {
      return true;
    }
  }

  getRestaurantInfo = (key) => {
    const restaurantInfo = storage.getRestaurantInfo();
    return restaurantInfo[key];
  };
  getPickupTime = () => {
    const restaurantInfo = storage.getRestaurantInfo();
    this.setState({ restaurantLocation: restaurantInfo.restaurantLocation });
    get(getPickupTime + "&restaurantNodeId=" + restaurantInfo.nodeId.toString())
      .then((result) => result.data)
      .then((response) => {
        if (response && response.status) {
          let data = JSON.parse(response.data);
          this.setState({
            pickupTime: data.RestaurantPickupTime,
            isShowRestaurantWaitingTime: data.IsShowRestaurantWaitingTime,
          });
        }
      });
  };
  handleCheckCameraAvailable = () => {
    let { isCameraAvailable } = this.state;
    isCameraAvailable = false;
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        var camera = devices.find((device) => device.kind == "videoinput");
        if (camera && camera.deviceId) {
          isCameraAvailable = true;
        }
        this.setState({ isCameraAvailable });
      })
      .then((stream) => {
        if (stream && stream.active) this.setState({ isCameraAvailable: true });
      })
      .catch((e) => {
        console.error(e);
        this.setState({ isCameraAvailable: false });
      });
  };
  componentDidMount() {
    storage.updateCartItemLastUpdatedOn();
    this.getTipConfigurations();
    this.getPickupTime();
    // this.SavePassInformationIntoStorage();
    this.checkAndSetSpecailText();
    this.handleCheckCameraAvailable();
  }
  checkAndSetSpecailText = () => {
    let { specials } = this.state;
    let passInformation = JSON.parse(_storage.getItem(PassInfoKey));
    if (passInformation && passInformation.specialText) {
      specials.text = passInformation.specialText;
      this.setState({ specials });
    }
  };
  SavePassInformationIntoStorage = () => {
    let passInfo = {
      firstName: "",
      lastName: "",
      passNumber: "",
      emailId: "",
      phoneNumber: "",
      isPassApplied: false,
      specialText: "",
    };
    _storage.setItem(PassInfoKey, JSON.stringify(passInfo));
  };
  handleOpenCameraPopup = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (stream && stream.active) this.setState({ isCameraAvailable: true });
      })
      .catch((err) => {});
  };
  onClose = () => {
    this.setState({ show: false });
  };

  handlePassInfoDialog = () => {
    this.props.checkAndRefreshScreenTime();
    let passInfo = this.state.passInfo;
    passInfo.dialogOpen = !passInfo.dialogOpen;
    this.setState({ passInfo });
  };

  handleSpecialInfo = (specialText) => {
    this.handlePassInfoDialog();
    let cartItems = this.getItemFromStorage();
    const specials = {
      text: specialText,
    };
    this.setSpecailTextToLocalStorage(specialText);
    this.setState({ specials, cartItems });
  };
  setSpecailTextToLocalStorage = (specialText) => {
    let passInformation = JSON.parse(_storage.getItem(PassInfoKey));
    passInformation["specialText"] = specialText;
    _storage.setItem(PassInfoKey, JSON.stringify(passInformation));
  };

  getTipConfigurations = () => {
    get(
      getTipConfiguration +
        "/?foodItemNodeIds='" +
        this.state.restaurantNodeId +
        "'"
    )
      .then((result) => result.data)
      .then((data) => {
        this.setState({ tipConfiguration: data });
      });
  };

  onCartScreenClose = () => {
    this.onClose();
    this.props.onCartScreenClose();
  };
  handlePassInfoLinkText = () => {
    //let text = "Get up to 10% off by entering your pass number.";
    let { kioskSetting, isCameraAvailable } = this.state;
    let text = isCameraAvailable
      ? kioskSetting && kioskSetting.IsKioskActive
        ? window.ENV.LookMeUpKioskText
        : window.ENV.LookMeUpCamera
      : window.ENV.LookMeUpNoCamera;
    if (this.state.specials.text) text = this.state.specials.text;
    return text;
  };

  getDiscountContainerForItem = (foodItem) => {
    if (
      foodItem.specialDiscount &&
      foodItem.specialDiscount.hasOwnProperty("discount") &&
      foodItem.specialDiscount.discount > 0
    ) {
      return (
        <div
          className="pl-2 details"
          key={foodItem.nodeId.toString() + "_discount"}
        >
          <span>Discount</span>
          <span className="float-right price">
            {formatPrice(foodItem.specialDiscount.discount)}
          </span>
          <p></p>
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    //console.log("cartItem - cartscreen", this.state.cartItems);
    return (
      <Fragment>
        <Dialog
          id="cartDialogContainer"
          fullScreen
          open={this.state.show}
          aria-labelledby="cart-dialog-title"
        >
          <DialogTitle className="text-center">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => this.onCartScreenClose()}
              className="modal-close"
            />
            <div> Cart </div>
          </DialogTitle>

          <DialogContent>
            <div id="cart">
              {this.state.cartItems.foodItems.map((item, key) => {
                return (
                  <div key={item.id} className="detail-section">
                    <div className="heading-section">
                      <p>{item.name}</p>
                      <p>{formatPrice(item.actualPrice * item.quantity)}</p>
                    </div>
                    {this.getDiscountContainerForItem(item)}
                    {item.customizations.map((customization, key) => {
                      return (
                        <div className="pl-2" key={customization.nodeId}>
                          <p>{customization.name.replace("Select a", "")}:</p>
                          <div>
                            {customization.customizationOptions.map(
                              (option, key) => {
                                return (
                                  <div
                                    className="pl-2 details"
                                    key={option.nodeId}
                                  >
                                    <span>{option.name}</span>
                                    {option.actualPrice > 0 && (
                                      <span className="float-right price">
                                        {formatPrice(
                                          option.actualPrice * item.quantity
                                        )}
                                      </span>
                                    )}
                                    <p></p>
                                    {this.getDiscountContainerForItem(option)}
                                  </div>
                                );
                              }
                            )}
                            {customization.customizationOptions.length ===
                              0 && (
                              <div className="pl-2 details">
                                <span>{customization.textBoxValue}</span>
                                <p></p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {item.customizations.length > 0 && (
                      <>
                        <Link
                          to={{
                            pathname: `/food-customization/${item.nodeId}/${item.customizeNodeId}/${item.id}`,
                          }}
                          className="edit"
                        >
                          Edit
                        </Link>
                      </>
                    )}

                    <div className="input-section">
                      <Form.Control
                        type="number"
                        id={item.nodeId}
                        value={item.quantity}
                        min={item.minimumQuantity}
                        max={item.maximumQuantity}
                        onChange={(e) =>
                          this.onQuantityChange(item, e.target.value)
                        }
                      />
                      <span onClick={() => this.removeItemfromCart(item)}>
                        Remove
                      </span>
                    </div>
                    {item.isValid === false && (
                      <div className="text-danger">
                        Value has to be between {item.minimumQuantity} and{" "}
                        {item.maximumQuantity}.
                      </div>
                    )}
                  </div>
                );
              })}
              {this.state.tipConfiguration && this.getTipSection()}
              <div
                className="passnumb-section d-flex"
                onClick={() => this.handlePassInfoDialog()}
              >
                {this.handlePassInfoLinkText()}
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
              {this.state.passInfo?.dialogOpen && (
                <PassInfoDialog
                  open={this.state.passInfo.dialogOpen}
                  onClose={() => this.handlePassInfoDialog()}
                  handleSpecialInfo={this.handleSpecialInfo}
                  checkAndRefreshScreenTime={
                    this.props.checkAndRefreshScreenTime
                  }
                  isCameraAvailable={this.state.isCameraAvailable}
                />
              )}
              <div className="total-section">
                <div>
                  <p>Subtotal</p>
                  <p>{this.calculateSubTotal()}</p>
                </div>
                {this.getDiscountContainerForTotal()}
                <div>
                  <p>Sales Tax</p>
                  <p>{this.calculateSalesTax()}</p>
                </div>
                <div>
                  {this.state.tipConfiguration &&
                    this.state.tipConfiguration.tipSetup !== 1 && (
                      <>
                        <p>Tip Amount</p>
                        <p>{formatPrice(this.calculateTipAmount())}</p>
                      </>
                    )}
                </div>
                <div>
                  <p>Total</p>
                  <p>{this.calculateTotal()}</p>
                </div>
                {this.state.isShowRestaurantWaitingTime
                  ? this.state.restaurantWaitingTimeMessage
                  : this.state.pickupTime && (
                      <div>
                        <p>Pickup Details: {this.state.pickupTime}</p>
                      </div>
                    )}
              </div>

              {!this.state.isOtherRestaurantFoodItemsAddedToCart && (
                <Link to="/checkout">
                  <Button
                    variant="danger"
                    disabled={this.state.checkoutButtonDisabled}
                  >
                    Check Out
                  </Button>
                </Link>
              )}

              {this.state.quantityModal.visible && (
                <QuantityInfoDialog
                  open={true}
                  quantity={this.state.quantityModal.quantity}
                  onClose={this.state.quantityModal.onCloseEventCallBack}
                />
              )}
            </div>

            {/* </Modal> */}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }

  onTipSelect = (tipSetup: Number, tipSelection: Number) => {
    this.props.checkAndRefreshScreenTime();
    let tipConfiguration = this.state.tipConfiguration;
    tipConfiguration.tipSetup = tipSetup;
    tipConfiguration.tipSelection = tipSelection;

    this.setState({ tipConfiguration: tipConfiguration });
    //this.state.tipConfiguration.tipSetup;
    //this.state.tipConfiguration.tipSelection
  };

  validateError = (nodeId, minimumQuantity, maximumQuantity) => {};
  handleClick(e) {
    // modify the state, this will automatically recall render() below.
    this.setState((prevState) => {
      return { animate: !prevState.animate };
    });
  }
  calculateSubTotal = () => {
    const info = storage.info();
    return formatPrice(info.totalPriceWithoutTax);
  };

  calculateSalesTax = () => {
    const info = storage.info();
    return formatPrice(info.totalSalesTax);
  };

  calculateTipAmount = () => {
    let tipAmount = 0;
    if (!this.state.isCustomAmountDisplay) {
      tipAmount = this.state.customTip;
    } else {
      if (
        this.state.tipConfiguration.tipSetup === 3 &&
        this.state.tipConfiguration.tipSelection === 2
      ) {
        tipAmount = parseFloat(
          (this.state.tipConfiguration.mondayTipPercentage / 100) *
            this.state.cartItems.totalPrice
        ).toFixed(2);
      } else if (
        this.state.tipConfiguration.tipSetup === 3 &&
        this.state.tipConfiguration.tipSelection === 3
      ) {
        tipAmount = parseFloat(
          (this.state.tipConfiguration.tuesdayTipPercentage / 100) *
            this.state.cartItems.totalPrice
        ).toFixed(2);
      } else if (
        this.state.tipConfiguration.tipSetup === 3 &&
        this.state.tipConfiguration.tipSelection === 4
      ) {
        tipAmount = parseFloat(
          (this.state.tipConfiguration.wednesdayTipPercentage / 100) *
            this.state.cartItems.totalPrice
        ).toFixed(2);
      } else if (
        this.state.tipConfiguration.tipSetup === 3 &&
        this.state.tipConfiguration.tipSelection === 5
      ) {
        tipAmount = parseFloat(
          (this.state.tipConfiguration.thursdayTipPercentage / 100) *
            this.state.cartItems.totalPrice
        ).toFixed(2);
      }
    }
    storage.updateTipAmount(tipAmount);
    if (this.state.customTipInPersentage) {
      tipAmount = (tipAmount / 100) * this.state.cartItems.totalPrice;
      storage.updateTipAmount(tipAmount);
    }
    return tipAmount;
  };

  getDiscountContainerForTotal = () => {
    const info = storage.info();
    if (info.totalDiscount > 0) {
      return (
        <div>
          <p>Guest Discount</p>
          <p>
            {"-"}
            {formatPrice(info.totalDiscount)}
          </p>
        </div>
      );
    } else {
      return null;
    }
  };

  removeItemfromCart = (foodItem) => {
    this.props.checkAndRefreshScreenTime();
    storage.removeFoodItemWithMax4Sale(
      foodItem,
      this.removeItemSuccessCallBack,
      this.removeItemFailureCallBack
    );
  };

  removeItemFailureCallBack = (foodItem, response) => {
    console.log(
      "Something went wrong while removing cart item from cache database!"
    );
    console.log("foodItem with response ", foodItem, response);
  };

  removeItemSuccessCallBack = (foodItem) => {
    const nodeId = foodItem.nodeId;
    storage.remove(nodeId, foodItem.id);
    // if(foodItem.customizations.length > 0){
    //   storage.remove(nodeId,foodItem.id);
    // }else{
    //   storage.remove(nodeId);
    // }
    const cartItems = this.getItemFromStorage();
    this.setState({ cartItems });
    if (cartItems.foodItems.length === 0) {
      this.onCartScreenClose();
    }
  };

  calculateTotal = () => {
    const info = storage.info();
    if (!info.totalDiscount) {
      info.totalDiscount = 0;
    }
    let total = Number(
      info.totalPriceWithoutTax + (info.totalSalesTax - info.totalDiscount)
    ).toFixed(2);
    total = parseFloat(total);
    total = total + parseFloat(info.tipAmount);
    return formatPrice(total);
  };

  handleCustomTipDollarClick = () => {
    this.setState({
      customTipInPersentage: true,
      isCustomAmountDisplay: false,
      customTipInDollar: false,
    });
  };

  handleCustomTipPercentageClick = () => {
    this.setState({
      customTipInPersentage: false,
      isCustomAmountDisplay: false,
      customTipInDollar: false,
    });
  };
  handleDefaultTipAmount = () => {
    this.setState({
      customTipInPersentage: false,
      isCustomAmountDisplay: true,
      customTipInDollar: false,
    });
  };
  getTipSection = () => {
    return (
      <>
        {this.state.tipConfiguration.tipSetup !== 1 && (
          <div className="tip-section">
            <div className="heading">
              {this.state.isCustomAmountDisplay &&
                !this.state.customTipInPersentage &&
                !this.state.customTipInDollar && (
                  <p> Select Tip Amount in Percentage</p>
                )}
              {!this.state.isCustomAmountDisplay &&
                !this.state.customTipInDollar &&
                this.state.customTipInPersentage && (
                  <p>Custom Tip in Percentage</p>
                )}
              {!this.state.isCustomAmountDisplay &&
                !this.state.customTipInPersentage &&
                !this.state.customTipInDollar && <p>Custom Tip in Dollars</p>}

              {!this.state.isCustomAmountDisplay &&
                !this.state.customTipInDollar &&
                this.state.customTipInPersentage && (
                  <p onClick={() => this.handleCustomTipPercentageClick()}>
                    Enter Custom Tips in Dollars
                  </p>
                )}

              {this.state.isCustomAmountDisplay &&
                !this.state.customTipInPersentage &&
                !this.state.customTipInDollar && (
                  <p onClick={() => this.handleCustomTipDollarClick()}>
                    Enter Custom Tips in Percentage
                  </p>
                )}

              {!this.state.isCustomAmountDisplay &&
                !this.state.customTipInPersentage &&
                !this.state.customTipInDollar && (
                  <p onClick={() => this.handleDefaultTipAmount()}>
                    Default Tips section
                  </p>
                )}

              {/* 
              {this.state.isCustomAmountDisplay ? (
                <p onClick={() => this.toggle()}>Enter Custom Amount in Dollars</p>
              ) : (
                <p onClick={() => this.toggle()}>Enter Tips in Percentage</p>
              )} */}
            </div>
            {this.state.isCustomAmountDisplay
              ? this.tipPercentages()
              : this.getInoutBox()}
          </div>
        )}
      </>
    );
  };

  tipPercentages() {
    return (
      <>
        <div className="box-section">
          {this.state.tipConfiguration && (
            <>
              <div
                key={this.state.tipConfiguration.mondayTipPercentage}
                className={
                  this.state.tipConfiguration.tipSetup === 3 &&
                  this.state.tipConfiguration.tipSelection === 2
                    ? "box tip-Selected"
                    : "box"
                }
                onClick={() => this.onTipSelect(3, 2)}
              >
                <p>{this.state.tipConfiguration.mondayTipPercentage} %</p>
                <p>
                  {parseFloat(
                    (this.state.tipConfiguration.mondayTipPercentage / 100) *
                      this.state.cartItems.totalPrice
                  ).toFixed(2)}
                </p>
              </div>

              <div
                key={this.state.tipConfiguration.tuesdayTipPercentage}
                className={
                  this.state.tipConfiguration.tipSetup === 3 &&
                  this.state.tipConfiguration.tipSelection === 3
                    ? "box tip-Selected"
                    : "box"
                }
                onClick={() => this.onTipSelect(3, 3)}
              >
                <p>{this.state.tipConfiguration.tuesdayTipPercentage} %</p>
                <p>
                  {parseFloat(
                    (this.state.tipConfiguration.tuesdayTipPercentage / 100) *
                      this.state.cartItems.totalPrice
                  ).toFixed(2)}
                </p>
              </div>

              <div
                key={this.state.tipConfiguration.wednesdayTipPercentage}
                className={
                  this.state.tipConfiguration.tipSetup === 3 &&
                  this.state.tipConfiguration.tipSelection === 4
                    ? "box tip-Selected"
                    : "box"
                }
                onClick={() => this.onTipSelect(3, 4)}
              >
                <p>{this.state.tipConfiguration.wednesdayTipPercentage} %</p>
                <p>
                  {parseFloat(
                    (this.state.tipConfiguration.wednesdayTipPercentage / 100) *
                      this.state.cartItems.totalPrice
                  ).toFixed(2)}
                </p>
              </div>

              <div
                key={this.state.tipConfiguration.thursdayTipPercentage}
                className={
                  this.state.tipConfiguration.tipSetup === 3 &&
                  this.state.tipConfiguration.tipSelection === 5
                    ? "box tip-Selected"
                    : "box"
                }
                onClick={() => this.onTipSelect(3, 5)}
              >
                <p>{this.state.tipConfiguration.thursdayTipPercentage} %</p>
                <p>
                  {parseFloat(
                    (this.state.tipConfiguration.thursdayTipPercentage / 100) *
                      this.state.cartItems.totalPrice
                  ).toFixed(2)}
                </p>
              </div>
            </>
          )}
        </div>
      </>
    );
  }

  // getBoxSection() {
  //   return (
  //     <div className="box-section">
  //       {TipAmount.map((item) => {
  //         return (
  //           <div key={item.amount} className="box" onClick={this.handleClick}>
  //             <p>{item.percentage}</p>
  //             <p>{item.amount}</p>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // }

  getInoutBox() {
    return (
      <>
        {this.state.tipConfiguration && (
          <>
            <Form.Control
              type="number"
              // min={this.state.tipConfiguration.customTipMinPercentage}
              // max={this.state.tipConfiguration.customTipMaxPercentage}
              value={this.state.customTip}
              onChange={(e) => this.onCustomTipChange(e.target.value)}
            />
          </>
        )}
      </>
    );
  }

  toggle() {
    this.props.checkAndRefreshScreenTime();
    this.setState({ isCustomAmountDisplay: !this.state.isCustomAmountDisplay });
  }

  onCustomTipChange = (value) => {
    this.props.checkAndRefreshScreenTime();
    if (
      value < 0 ||
      value > this.state.tipConfiguration.customTipMaxPercentage
    ) {
      value = 0;
    }
    this.setState({ customTip: value }, () => {
      this.handleCheckoutButtonDisabled();
      this.calculateSubTotal(value);
    });
  };

  onQuantityChange = (foodItem, value) => {
    this.props.checkAndRefreshScreenTime();
    value = Number(value);
    //let cartItems = this.state.cartItems;
    let item = foodItem; // cartItems.foodItems.filter((x) => x.nodeId === nodeId)[0];
    if (value < item.minimumQuantity || value > item.maximumQuantity) {
      item.isValid = false;
    } else {
      this.addQuantityIntoFoodItem(item, value);
    }
    this.handleCheckoutButtonDisabled();
  };

  handleCheckoutButtonDisabled = () => {
    const inValidItems = this.state.cartItems.foodItems.filter(function (item) {
      return item.isValid === false;
    });
    if (inValidItems.length > 0) {
      this.setState({ checkoutButtonDisabled: true });
    } else {
      // Check the custom tip
      if (!this.state.isCustomAmountDisplay) {
        if (
          this.state.customTip <
            this.state.tipConfiguration.customTipMinPercentage ||
          this.state.customTip >
            this.state.tipConfiguration.customTipMaxPercentage
        ) {
          this.setState({ checkoutButtonDisabled: true });
        } else {
          this.setState({ checkoutButtonDisabled: false });
        }
      } else {
        this.setState({ checkoutButtonDisabled: false });
      }
    }
  };

  quantityModalCloseCallBack = () => {
    this.props.checkAndRefreshScreenTime();
    let forceReload = false;
    let quantityModal = this.state.quantityModal;
    if (quantityModal.quantity === 0) forceReload = true;
    if (forceReload) {
    }
    quantityModal.visible = false;
    quantityModal.quantity = 0;
    this.setState({
      quantityModal,
    });
  };
  handleMax4SaleFailureCallBack = (response) => {
    this.props.checkAndRefreshScreenTime();
    let quantity = 0;
    if (response.data) {
      const quantityJson = JSON.parse(response.data);
      quantity = quantityJson.availableQty;
    }
    let quantityModal = this.state.quantityModal;
    quantityModal.quantity = quantity;
    quantityModal.visible = true;
    this.setState({ quantityModal });
  };

  handleMax4SaleSuccessCallBack = (foodItem, addingQty) => {
    foodItem.isValid = true;
    foodItem.quantity += Number(addingQty);
    storage.update(foodItem, foodItem.customizations);
    this.checkAndUpdateDiscountForFoodItems();
  };

  checkAndUpdateDiscountForFoodItems = () => {
    let passInformation = this.getPassInfo();
    if (passInformation && passInformation.passNumber) {
      let storedItems = storage.getAllFoodItems();
      let foodItems = [];
      if (storedItems.foodItems.length > 0) {
        let storedfoodItems = storedItems.foodItems;
        storedfoodItems.forEach((foodItem) => {
          let customizations = foodItem.customizations;
          delete foodItem.customizations;
          foodItems.push({ customizations, foodItem });
        });
      }
      if (foodItems && foodItems.length > 0) {
        const restaurantInfo = storage.getRestaurantInfo();
        const passInfoWithFoodItems = {
          passNumber: passInformation.passNumber,
          lastName: passInformation.lastName,
          firstName: passInformation.firstName,
          emailId: passInformation.emailId,
          phoneNumber: passInformation.phoneNumber,
          foodItems,
          salesPoint: restaurantInfo.salesPointName,
          rootName: ItemTreeRoot,
        };
        this.getDiscountsforFoodItems(passInfoWithFoodItems);
      }
    } else {
      this.setState({ cartItems: this.getItemFromStorage() });
    }
  };

  getDiscountsforFoodItems = (passInfoWithFoodItems) => {
    post(getDiscountsForFoodItems, passInfoWithFoodItems)
      .then((result) => result.data)
      .then((data) => {
        this.handleDiscountResponses(data);
      })
      .catch((error) => {
        this.showToasterMessage(
          "You have not entered a valid pass number",
          "e"
        );
        this.setState({ isSendEncryptedTextToPassInfo: true });
      });
  };

  handleDiscountResponses = (response) => {
    if (response && response.specialNames && response.specialNames.length > 0) {
      this.updateFoodItemsWithSpecialDiscounts(response);
    }
  };

  updateFoodItemsWithSpecialDiscounts = (response) => {
    let foodItems = [];
    response.foodItems.forEach((item) => {
      let foodItem = item.foodItem;
      let specialDiscount = foodItem.specialDiscount;
      if (specialDiscount != null) {
        specialDiscount.actualPrice = foodItem.actualPrice * foodItem.quantity;
        if (
          specialDiscount.discountAmount &&
          specialDiscount.discountAmount > 0
        )
          specialDiscount.discount =
            specialDiscount.actualPrice - specialDiscount.discountAmount;
        else specialDiscount.discount = 0;
      }
      let customizations = item.customizations;
      customizations.forEach((customization) => {
        customization.customizationOptions.forEach((option) => {
          let _specialDiscount = option.specialDiscount;
          if (_specialDiscount != null) {
            _specialDiscount.actualPrice =
              option.actualPrice * foodItem.quantity;
            if (
              _specialDiscount.discountAmount &&
              _specialDiscount.discountAmount > 0
            )
              _specialDiscount.discount =
                _specialDiscount.actualPrice - _specialDiscount.discountAmount;
            else _specialDiscount.discount = 0;
          }
        });
      });
      foodItem.customizations = customizations;
      foodItems.push(foodItem);
    });
    let cartItems = storage.getAllFoodItems();
    cartItems.employeePurchase = response.employeePurchase ? true : false;
    cartItems.foodItems = foodItems;
    cartItems = storage.calculateCartFigures(cartItems);
    _storage.setItem(CartItemKey, JSON.stringify(cartItems));
    this.setState({ cartItems });
  };

  getPassInfo = () => {
    return JSON.parse(_storage.getItem(PassInfoKey));
  };

  addQuantityIntoFoodItem = (foodItem, value) => {
    const changedQty = value - foodItem.quantity;
    if (changedQty !== 0) {
      storage.verifyFoodItemWithMax4Sale(
        foodItem,
        changedQty,
        this.handleMax4SaleSuccessCallBack,
        this.handleMax4SaleFailureCallBack
      );
    }
  };
}

export default Cart;
