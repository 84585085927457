import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { VerifyOrderKey } from "../../Utility/Config";
import {
  sendVerificationLinkToCustomer,
  getGuestInfoByGuestNumber,
} from "../../Utility/URLService";
import Spinner from "../../Utility/Spinner";
import Toaster, { ToasterHelper } from "../../Utility/Toaster";
import { Storage as _storage } from "../../Utility/Storage";
import { post } from "../../Utility/HttpService";
import { commonHelper } from "../../Utility/Common";
import { storage } from "../../Utility/CartStorage";
import { KIOSKSetting } from "../../Utility/Config";
import { get } from "../../Utility/HttpService";

class VerifyOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kioskSetting: JSON.parse(_storage.getItem(KIOSKSetting)),
      screenRefreshTime: 0,
      verifyOrderForm: {
        selectedVerifyMethod: "",
        guestInfo: this.getGuestInfo(),
      },
      spinner: {
        show: false,
      },
      toaster: [],
      checkSendButton: false,
      IsOrderResentVerificationLink: false,
    };
  }
  componentDidMount = () => {
    this.checkAndRedirectToMainPage();
    this.getGuestIntormationByGuestNumber();
  };
  getGuestInfo = () => {
    return JSON.parse(_storage.getItem(VerifyOrderKey));
  };
  getGuestIntormationByGuestNumber = () => {
    let { verifyOrderForm } = this.state;
    if (verifyOrderForm.guestInfo.guestNumber) {
      let url = `${getGuestInfoByGuestNumber}${verifyOrderForm.guestInfo.guestNumber}`;
      get(url)
        .then((result) => result.data)
        .then((data) => {
          if (
            data &&
            this.checkGuestContainsEmailAndPhone(
              data.email?.trim(),
              data.phoneNumber?.trim()
            )
          ) {
            verifyOrderForm.guestInfo["email"] = data.email;
            verifyOrderForm.guestInfo["phoneNumber"] = data.phoneNumber;
          } else {
            verifyOrderForm.guestInfo = this.getGuestInfo();
          }
          this.setState({ verifyOrderForm }, () => {
            this.checkSelectedVerifyMethod();
          });
        })
        .catch((error) => {
          this.showSpinner(false);
          this.showToasterMessage(
            "Some error occured. Please try again .",
            "w"
          );
        });
    }
  };
  checkGuestContainsEmailAndPhone = (email, phone) => {
    let isEmailValid = false;
    let isPhoneValid = false;
    if (phone) {
      phone = phone.replace(/[^\d]/g, "");
      var phoneNoRegex = /^\d{10}$/;
      isPhoneValid = phone.match(phoneNoRegex);
    }
    if (email) {
      var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      isEmailValid = emailRegex.test(email);
    }
    return isEmailValid && isPhoneValid;
  };
  checkSelectedVerifyMethod = () => {
    let { verifyOrderForm } = this.state;
    verifyOrderForm.selectedVerifyMethod =
      verifyOrderForm.guestInfo.email != "" ? "email" : "phone";
    this.setState({ verifyOrderForm });
  };
  handleRadioSelection = (type) => {
    let { verifyOrderForm } = this.state;
    verifyOrderForm.selectedVerifyMethod = type;
    this.setState({ verifyOrderForm });
  };
  handleSubmit = (event) => {
    let { verifyOrderForm, IsOrderResentVerificationLink } = this.state;
    let guestInfo = verifyOrderForm.guestInfo;
    let customerInfo = {
      guestNumber: guestInfo.guestNumber,
      type: verifyOrderForm.selectedVerifyMethod,
      verifyOrderNumber: guestInfo.verifyOrderNumber,
      IsOrderResentVerificationLink,
    };
    this.showSpinner(true);
    event.preventDefault();
    event.stopPropagation();
    post(sendVerificationLinkToCustomer, customerInfo)
      .then((result) => result.data)
      .then((data) => {
        this.showSpinner(false);
        console.log(data.status);
        if (data.status) {
          this.showToasterMessage("Link sent successfully.", "s");
          //  this.props.history.push("/orderVerification");
        } else {
          this.showToasterMessage(data.message);
          setTimeout(() => {
            this.props.history.push("/");
          }, 5000);
        }
      })
      .catch((error) => {
        this.showSpinner(false);
        this.showToasterMessage("Some error occured. Please try again.", "w");
      });
    this.setState({
      checkSendButton: true,
      IsOrderResentVerificationLink: true,
    });
  };
  showSpinner = (show) => {
    let spinner = this.state.spinner;
    spinner.show = show;
    this.setState({ spinner });
  };
  removeToasterMessage = () => {
    this.setState({ toaster: [] });
  };
  showToasterMessage = (message, type, time = 5000) => {
    const toaster = ToasterHelper.show(message, type, time);
    this.setState({ toaster });
  };
  getEncryptedValue = (type, value) => {
    if (type === "phone") value = `***-****-${value.slice(value.length - 4)}`;
    else if (type === "email") value = commonHelper.protectEmail(value);
    return value;
  };
  confirmationTimer = () => {
    this.confirmationTimer = setInterval(() => {
      let { screenRefreshTime } = this.state;
      if (screenRefreshTime === 0) {
        this.refreshScreen();
      } else {
        screenRefreshTime = screenRefreshTime - 1;
        this.setState({ screenRefreshTime });
      }
    }, 1000);
  };
  checkAndRedirectToMainPage = () => {
    let { kioskSetting, screenRefreshTime } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      screenRefreshTime = parseInt(kioskSetting.Payment);
      if (screenRefreshTime > 0) {
        this.setState({ screenRefreshTime }, () => {
          this.confirmationTimer();
        });
      }
    }
  };
  refreshScreen = () => {
    let redirectURL = _storage.getRedirectURL();
    this.setState({ isRefreshScreen: true }, () => {
      this.removeCartItemsFromStorage();
      this.props.history && this.props.history.push(redirectURL);
    });
  };
  removeCartItemsFromStorage = () => {
    storage.removeAll();
  };
  checkAndRefreshScreenTime = () => {
    let { screenRefreshTime, kioskSetting } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      screenRefreshTime =
        parseInt(kioskSetting.Payment) > 0 ? parseInt(kioskSetting.Payment) : 0;
      this.setState({ screenRefreshTime });
    }
  };
  render() {
    let { verifyOrderForm, spinner, toaster } = this.state;
    return (
      <div id="verifyOrder">
        <Spinner show={spinner.show} value={spinner}></Spinner>
        {toaster?.length === 1 && (
          <Toaster
            message={toaster[0].message}
            type={toaster[0].type}
            id={toaster[0].id}
            closeCallBack={this.removeToasterMessage}
          />
        )}
        <p className="orderVerifyText">
          Your order is not completed yet! <br></br>
          You are one step away from completing your order
        </p>
        <p>To submit this order, please select a verification method:</p>
        <Form onSubmit={this.handleSubmit} className="d-grid">
          {verifyOrderForm.guestInfo.email != "" && (
            <Form.Row>
              <Form.Check
                name="verifyByEmailRadio"
                type="radio"
                id="verifyByEmail"
                label={`By email: ${this.getEncryptedValue(
                  "email",
                  verifyOrderForm.guestInfo?.email
                )}`}
                className={"radiButtonLabelColor mar-5"}
                onChange={(e) => this.handleRadioSelection("email")}
                checked={verifyOrderForm.selectedVerifyMethod === "email"}
              />
            </Form.Row>
          )}
          {verifyOrderForm.guestInfo.phoneNumber != "" && (
            <Form.Row>
              <Form.Check
                name="verifyByPhoneRadio"
                type="radio"
                id="verifyByPhone"
                label={`By text: ${this.getEncryptedValue(
                  "phone",
                  verifyOrderForm.guestInfo?.phoneNumber
                )}`}
                className={"radiButtonLabelColor mar-5"}
                onChange={(e) => this.handleRadioSelection("phone")}
                checked={verifyOrderForm.selectedVerifyMethod === "phone"}
              />
            </Form.Row>
          )}
          {this.state.checkSendButton ? (
            <div className="linkText">
              Please follow the link sent to your email/phone to complete your
              order
            </div>
          ) : null}
          <Button
            type="submit"
            value="Submit"
            variant="danger"
            className="verifyOrderSubmitbtn"
            disabled={
              verifyOrderForm.guestInfo.phoneNumber == "" &&
              verifyOrderForm.guestInfo.email == ""
                ? true
                : false
            }
          >
            {this.state.checkSendButton
              ? "Resend verification link"
              : "Send verification link"}
          </Button>
        </Form>
      </div>
    );
  }
}

export default VerifyOrder;
