import {ItemTreeRoot} from './Config';

const baseURL = window.ENV.API;
export const getRestaurantsURL = baseURL + "Restaurant/GetRestaurants?rootName="+ItemTreeRoot;
export const getRestarurantItemsURL = baseURL + "Restaurant/GetMainMenu?rootName="+ItemTreeRoot;
export const getFoodItems = baseURL + "FoodItem/GetFoodItems"//"FNB/GetFoodCategory";
export const getFoodItemWithCustomization = baseURL + "FoodItem/GetFoodItemWithCustomization";
export const getTipConfiguration = baseURL +"FoodItem/GetTipConfiguration";

export const getMemebershipValidation = baseURL +"Membership/GetMemebershipDetails";
export const validateMemberShipNumber = baseURL +"Membership/ValidateMembershipNumber";
export const getDiscountsForFoodItems = baseURL +"FoodItem/GetDiscountsForFoodItems";
export const getGiftCardInformation = baseURL +"GiftCard/GetGiftCardInformation";
export const processSale = baseURL +"Sales/ProcessSales";
export const verifyProcessSale = baseURL +"Sales/VerifyProcessSales";
export const checkOrderStatus = baseURL + "Sales/CheckOrderStatus";
export const checkOrderIsProcessed = baseURL + "Sales/CheckOrderIsProcessed?verifyOrderNumber=";
export const sendVerificationLinkToCustomer = baseURL + "Sales/SendVerificationLinkToCustomer";
export const verifySaleOrder = baseURL + "Sales/VerifySaleOrder?token=";

export const initializeCartId = baseURL + "Cart/InitializeCartId?cartId=";
export const verifyCartItem = baseURL + "Cart/VerifyCartItem";
export const removeCartItem = baseURL + "Cart/RemoveCartItem";
export const rollBackCartItemDetails = baseURL + "Cart/RollBackCartItemDetails";
export const checkCartIdExists = baseURL + "Cart/CheckCartIdExists";

export const getPickupTime = baseURL + "Restaurant/GetPickupTime?rootName="+ItemTreeRoot;
export const getRestarurantInfo = baseURL + "Restaurant/GetRestaurantInformation?rootName="+ItemTreeRoot+"&menuNodeId=";
export const getRestaurantTableNumbers = baseURL + "Restaurant/GetRestaurantTableNumbers?rootName="+ItemTreeRoot;
export const getSettingInfo = baseURL + "Restaurant/GetSettingInfo?settingName=";
export const token = baseURL + "token";
export const getGiftCardRanges = baseURL + "GiftCard/GetGiftCardRanges";
export const getGiftCardsByGuestNumber = baseURL + "GiftCard/GetGiftCardsByGuestNumber?guestNumber=";

export const getPassInfoByEncryptedText = baseURL + "Pass/GetPassInfoByEncryptedText?rootName="+ItemTreeRoot+ "&encrptedText=";
export const getCardIdFromGuestNumber = baseURL + "Guest/GetCardIdFromGuestNumber?guestNumber=";
export const getGuestInfoByGuestNumber = baseURL+ "Guest/GetGuestInfoByGuestNumber?guestNumber=";
export const getImage = baseURL + "Image/GetImage?imagePath=";
