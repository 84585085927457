import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import foodIcon from "../../assets/images/food.png";
import { FoodItemCategory } from "../../Models/FoodItemCategory";
import { CommonResponse } from "../../Models/CommonResponse";
import CartExpirationDialog from "../../Utility/CartExpirationDialog";
import { storage } from "../../Utility/CartStorage";
import { Storage } from "../../Utility/Storage";
import { formatPrice } from "../../Utility/Common";
import {
  ExpiryCheck_IntervalMinutes,
  KIOSKSetting,
} from "../../Utility/Config";
import { get, post } from "../../Utility/HttpService";
import {
  getFoodItems,
  checkCartIdExists,
  getRestarurantInfo,
  getSettingInfo,
  getImage,
} from "../../Utility/URLService";
import Header from "../Header/NavigationHeader";
import Footer from "./Footer";
import Cart from "../../components/Cart/Cart";
import QuantityInfoDialog from "../QuantityInfo/QuantityInfo";
import Spinner from "../../Utility/Spinner";

class ExpandedMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAvailable: false,
      count: 0,
      totalPrice: 0,
      navigateFrom: "expanded-menu",
      restaurantNodeId: 0, //storage.getRestaurantInfo().nodeId,
      menuNodeId: props.match.params.menuNodeId,
      Modal: {
        visible: false,
        cartRemoval: false,
        closeEventCallBack: this.expirationModalCloseCallback,
      },
      cartModal: {
        visible: false,
      },
      quantityModal: {
        visible: false,
        onCloseEventCallBack: this.quantityModalCloseCallBack,
        quantity: 0,
      },
      spinner: {
        show: false,
        //handleClose: "",
      },
      heading: "SELECT YOUR ITEMS",
      kioskSetting: JSON.parse(Storage.getItem(KIOSKSetting)),
      screenRefreshTime: 0,
      isRefreshScreen: false,
    };
  }

  showSpinner = (show) => {
    let spinner = this.state.spinner;
    spinner.show = show;
    this.setState({ spinner });
  };

  componentWillMount() {
    const menuNodeId = this.props.match.params.menuNodeId;
    if (isNaN(menuNodeId)) {
      this.refreshScreen(true);
    } else this.checkRestaurantsInfo(menuNodeId);
    //storage.updateMenuNodeId(menuNodeId);
  }

  checkRestaurantsInfo = (menuNodeId) => {
    //const info = storage.getRestaurantInfo();
    //if (!info) {
    //check restaurant info not exists in session storage
    get(getRestarurantInfo + menuNodeId)
      .then((result) => result.data)
      .then((data: any) => {
        if (data && data.status) {
          let restaurantInfo = data.data;
          Storage.updateRestaurantInfo(JSON.stringify(restaurantInfo));
          storage.updateMenuNodeId(menuNodeId);
          this.setState({ restaurantNodeId: restaurantInfo.nodeId });
          this.getFoodCategoryList();
        } else {
          this.refreshScreen(true);
        }
      })
      .catch((error) => {
        console.log(error && error.message);
        this.refreshScreen(true);
      });
    // } else {
    //   this.setState({ restaurantNodeId: info.nodeId });
    // }
    //storage.updateMenuNodeId(menuNodeId);
  };

  quantityModalCloseCallBack = () => {
    let forceReload = false;
    let quantityModal = this.state.quantityModal;
    if (quantityModal.quantity === 0) forceReload = true;
    if (forceReload) {
      this.getFoodCategoryList();
    }
    quantityModal.visible = false;
    quantityModal.quantity = 0;
    this.setState({
      quantityModal,
    });
  };

  componentDidMount() {
    //this.getFoodCategoryList();
    this.cartExpiryTimeOut = setInterval(
      this.checkCartExpiryTimeOut,
      ExpiryCheck_IntervalMinutes * 60 * 1000
    );
    storage.initializeCart();
    this.getCartCount();
    this.getQueryParams();
  }
  getQueryParams = () => {
    if (window.location.search) {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams) {
        let kioskValue = queryParams.get("kiosk");
        if (kioskValue && kioskValue.toString().toLowerCase() === "true") {
          this.checkAndGetKioskSetting();
        }
      }
    } else {
      this.checkAndRedirectToMainPage();
    }
  };
  checkAndGetKioskSetting = () => {
    let { kioskSetting, menuNodeId } = this.state;
    if (kioskSetting) {
      kioskSetting["IsKioskActive"] = true;
      kioskSetting["RedirectUrl"] = `/food-categories/${menuNodeId}?kiosk=true`;
      this.SaveKIOSKInfoIntoStorage(kioskSetting);
    } else {
      this.getSettingInfo("kiosksetting");
    }
  };
  getSettingInfo = (settingName) => {
    let { menuNodeId } = this.state;
    let url = `${getSettingInfo}${settingName}`;
    get(url)
      .then((result) => result.data)
      .then((data) => {
        if (data) {
          let kioskSetting = data;
          if (kioskSetting) {
            kioskSetting["IsKioskActive"] = true;
            kioskSetting[
              "RedirectUrl"
            ] = `/food-categories/${menuNodeId}?kiosk=true`;
            this.SaveKIOSKInfoIntoStorage(kioskSetting);
          }
        }
      });
  };
  SaveKIOSKInfoIntoStorage = (kioskSetting) => {
    this.setState({ kioskSetting }, () => {
      this.checkAndRedirectToMainPage();
    });
    Storage.setItem(KIOSKSetting, JSON.stringify(kioskSetting));
  };
  getMealTypeAvailableConfig = () => {
    let isAvailable = true;
    const restaurantDetails = storage.getRestaurantInfo();
    const foodItemCategories = restaurantDetails.foodItemCategories;
    foodItemCategories.some((foodItemCategory) => {
      const foodItems = foodItemCategory.foodItems;
      const item = foodItems.filter((foodItem) => {
        return foodItem.nodeId.toString() === restaurantDetails.menuNodeId;
      });
      if (item && item.length === 1) {
        isAvailable = foodItemCategory.isAvailable;
        return true;
      }
    });
    this.setState({ isAvailable });
  };

  getFoodCategoryList() {
    this.showSpinner(true);
    const restaurantInfo = storage.getRestaurantInfo();
    if (restaurantInfo) {
      get(
        getFoodItems +
          "?restaurantNodeId=" +
          restaurantInfo.nodeId +
          "&nodeId=" +
          restaurantInfo.menuNodeId
      )
        .then((result) => result.data)
        .then((data) => {
          if (data && data.status) {
            this.setState({ foodItemCategory: data.data }, () => {
              let foodItemCategory = this.state.foodItemCategory;
              let foodItems = foodItemCategory.foodItems;
              if (foodItems && foodItems.length > 0) {
                foodItems.map((a) => {
                  if (a.imagePath) {
                    get(getImage + a.imagePath, "blob")
                      .then((result) => result.data)
                      .then((data) => {
                        let url = window.URL.createObjectURL(new Blob([data]));
                        a["imageSrc"] = url;
                        this.setState({ foodItemCategory });
                      });
                  }
                  this.setState({ foodItemCategory });
                  this.getMealTypeAvailableConfig();
                  this.showSpinner(false);
                });
              } else {
                this.getMealTypeAvailableConfig();
                this.showSpinner(false);
              }
            });
            // this.getMealTypeAvailableConfig();
            // this.showSpinner(false);
          } else {
            this.refreshScreen(true);
          }
        })
        .catch((error) => {
          console.log(error && error.message);
          this.refreshScreen(true);
        });
    }
  }

  getCartCount = () => {
    let cartInfo = storage.info();
    this.setState({
      count: cartInfo.totalQuantityCount,
      totalPrice: cartInfo.totalPrice,
    });
  };

  onViewCart = () => {
    //this.state.screenRefreshTime > 0 && clearTimeout(this.confirmationTimer);
    this.checkAndRefreshScreenTime();
    let cartInfo = storage.info();
    if (cartInfo.totalCount > 0) {
      get(checkCartIdExists + "?cartId=" + cartInfo.cartId)
        .then((result) => result.data)
        .then((data: CommonResponse) => {
          if (data.status) {
            // cartId exists
            let cartModal = {
              ...this.state.cartModal,
              visible: true,
            };
            this.setState({ cartModal });
          } else {
            // cartId not exists
            this.showCartRemovalMessage();
          }
        });
    }
  };

  showCartRemovalMessage = () => {
    let Modal = {
      ...this.state.Modal,
      visible: true,
      cartRemoval: true,
    };
    this.setState({ Modal });
  };
  confirmationTimer = () => {
    this.confirmationTimer = setInterval(() => {
      let { screenRefreshTime } = this.state;
      if (screenRefreshTime === 0) {
        this.refreshScreen();
      } else {
        screenRefreshTime = screenRefreshTime - 1;
        this.setState({ screenRefreshTime });
      }
    }, 1000);
  };
  checkAndRedirectToMainPage = () => {
    let { kioskSetting, screenRefreshTime } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      screenRefreshTime = parseInt(kioskSetting.MenuSelection);
      if (screenRefreshTime > 0) {
        this.setState({ screenRefreshTime }, () => {
          this.confirmationTimer();
        });
      }
    }
  };
  refreshScreen = (toMainUrl = false) => {
    let redirectURL = toMainUrl ? "/" : Storage.getRedirectURL();
    if (redirectURL && redirectURL.includes("/food-categories/"))
      this.closeAllModal();

    this.setState({ isRefreshScreen: true }, () => {
      this.removeCartItemsFromStorage();
      this.props.history && this.props.history.push(redirectURL);
    });
  };
  removeCartItemsFromStorage = () => {
    storage.removeAll();
  };
  closeAllModal = () => {
    let { quantityModal, cartModal } = this.state;
    cartModal.visible = false;
    quantityModal.visible = false;
    quantityModal.quantity = 0;
    this.setState({ cartModal, quantityModal });
  };
  checkAndRefreshScreenTime = () => {
    let { screenRefreshTime, kioskSetting } = this.state;
    if (kioskSetting && kioskSetting.IsKioskActive) {
      screenRefreshTime =
        parseInt(kioskSetting.MenuSelection) > 0
          ? parseInt(kioskSetting.MenuSelection)
          : 0;
      this.setState({ screenRefreshTime });
    }
  };
  render() {
    return (
      <div id="expandedMenu">
        <Spinner
          show={this.state.spinner.show}
          value={this.state.spinner}
        ></Spinner>
        <Header value={this.state} cartIconClickEvent={this.onViewCart} />
        <div className="subHeading">
          <p>
            <img width="31" src={foodIcon} alt="food icon"></img>
            {this.state.foodItemCategory && this.state.foodItemCategory.name}
          </p>
        </div>
        {this.state.foodItemCategory &&
          this.state.foodItemCategory.foodItems &&
          this.getMenuList()}
        {this.state.cartModal.visible && (
          <Cart
            onCartScreenClose={this.onCartScreenClose}
            expandedMenuParams={this.props.match.params}
            checkAndRefreshScreenTime={this.checkAndRefreshScreenTime}
          ></Cart>
        )}
        {this.state.count > 0 && (
          <div onClick={() => this.onViewCart()}>
            <Footer
              amount={this.state.totalPrice}
              isDisplayFooter={this.state.count > 0}
            />
          </div>
        )}
        {this.state.Modal.visible && (
          <CartExpirationDialog modal={this.state.Modal} />
        )}

        {this.state.quantityModal.visible && (
          <QuantityInfoDialog
            open={true}
            quantity={this.state.quantityModal.quantity}
            onClose={this.state.quantityModal.onCloseEventCallBack}
          />
        )}
      </div>
    );
  }

  getMenuList() {
    return this.state.foodItemCategory.foodItems.map((item, key) => {
      return (
        <Fragment key={key}>
          <div className="menuList">
            <div className="display-item">
              {item.imageSrc && (
                <span className="item-image">
                  <img src={item.imageSrc} className="image" />
                </span>
              )}
              <div className="item-name-and-desc">
                <div>{item.name}</div>
                <div>{formatPrice(item.price)}</div>
              </div>
            </div>
            <p> {item.description} </p>
            {this.state.isAvailable && this.getButtons(item)}
          </div>
          <div className="divider"></div>
        </Fragment>
      );
    });
  }

  getAddToOrderButton = (item) => {
    if (!this.isOtherRestaurantFoodItemsAddedToCart()) {
      return (
        <Button
          onClick={() => this.handleAddtoCartClick(item)}
          variant="danger"
          className="addOrderbtn"
        >
          + Add to Order
        </Button>
      );
    } else {
      return <></>;
    }
  };

  getCustomizeButton = (item) => {
    return (
      <Link
        to={{
          pathname: `/food-customization/${item.nodeId}/${item.customizeNodeId}`,
          state: { prevPath: this.props.location.pathname },
        }}
      >
        <Button variant="danger" className="custBtn">
          <span role="img" aria-label="cust icon">
            ✨
          </span>{" "}
          Customize
        </Button>
      </Link>
    );
  };

  getCustomizationButtons = (item) => {
    if (!this.isOtherRestaurantFoodItemsAddedToCart()) {
      if (item.hideAddtoCartButton) {
        return this.getCustomizeButton(item);
      } else {
        return (
          <Fragment>
            {this.getAddToOrderButton(item)}
            {this.getCustomizeButton(item)}
          </Fragment>
        );
      }
    } else {
      return <></>;
    }
  };

  // Handle different restaurant items check
  isOtherRestaurantFoodItemsAddedToCart() {
    let cartInfo = storage.info();
    let restaurantInfo = storage.getRestaurantInfo();
    if (
      cartInfo.restaurantNodeId === 0 ||
      cartInfo.restaurantNodeId === restaurantInfo.nodeId ||
      cartInfo.totalPrice === 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  getButtons(item) {
    return (
      <div className="btn-section">
        {!item.isCustomizable && this.getAddToOrderButton(item)}
        {item.isCustomizable && this.getCustomizationButtons(item)}
      </div>
    );
  }

  handleAddtoCartClick = (foodItem) => {
    this.showSpinner(true);
    storage.verifyFoodItemWithMax4Sale(
      foodItem,
      1,
      this.handleMax4SaleSuccessCallBack,
      this.handleMax4SaleFailureCallBack
      //this.addtoCartClickCount
    );
    this.checkAndRefreshScreenTime();
  };

  handleMax4SaleFailureCallBack = (response) => {
    if (response.message === "cartId_unavailable") {
      this.showCartRemovalMessage();
    } else {
      let quantity = 0;
      if (response.data) {
        const quantityJson = JSON.parse(response.data);
        quantity = quantityJson.availableQty;
      }
      let quantityModal = this.state.quantityModal;
      quantityModal.quantity = quantity;
      quantityModal.visible = true;
      this.setState({ quantityModal });
    }
    this.showSpinner(false);
  };

  handleMax4SaleSuccessCallBack = (foodItem, quantity) => {
    const storedItem = storage.getFoodItem(foodItem.nodeId);
    if (storedItem && storedItem.length > 0) {
      let selectedItem = storedItem.filter((item) => {
        return item.customizations.length === 0;
      });
      if (selectedItem && selectedItem.length === 1) {
        quantity = selectedItem[0].quantity + quantity;
      }
    }
    foodItem.quantity = quantity;
    storage.update(foodItem);
    this.getCartCount();
    this.showSpinner(false);
  };

  onCartScreenClose = () => {
    this.checkAndRefreshScreenTime();
    let cartModal = {
      ...this.state.cartModal,
      visible: false,
    };
    this.setState({ cartModal });
    this.getCartCount();
    this.getFoodCategoryList();
  };

  expirationModalCloseCallback = (type) => {
    if (this.state.Modal.cartRemoval) {
      storage.reInitCartWhenCartIdInvalid();
    } else {
      let Modal = {
        ...this.state.Modal,
        visible: false,
      };
      this.setState({ Modal });
      this.getCartCount();
    }
    if (type === "Expired") {
      let redirectURL = Storage.getRedirectURL();
      this.props.history.push(redirectURL);
    }
  };

  componentWillUnmount() {
    clearInterval(this.cartExpiryTimeOut);
    this.state.screenRefreshTime > 0 && clearTimeout(this.confirmationTimer);
  }

  checkCartExpiryTimeOut = () => {
    if (!this.state.Modal.visible) {
      let Modal = {
        ...this.state.Modal,
        visible: false,
      };
      if (storage.checkCartItemsExpiration()) {
        Modal.visible = true;
      }
      this.setState({ Modal });
    }
  };
}

export default ExpandedMenu;
